import React, { useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import newOrderProps from './newOrderProps';
import { getUserMachines } from 'endpoints/machines';
import { getUserNavigators } from 'endpoints/navigators';
import { getUserMaps } from 'endpoints/fieldMaps';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularLoader from 'components/Loader/CircularLoader';
import { createTask, updateTask } from 'endpoints/tasks';
import { workTypes } from 'const/roles';
export default function CreateUserModal({
  modalOpen,
  closeModal,
  selectedOrder,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();
  const queryClient = useQueryClient();
  const userId = JSON.parse(localStorage.getItem('userData'))?.id;

  const { mutate } = useMutation(selectedOrder ? updateTask : createTask);

  const { data: machines, isLoading: machinesLoading } = useQuery(
    'getUserMachines',
    () => getUserMachines(userId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: navigators, isLoading: navigatorsLoading } = useQuery(
    'getUserNavigators',
    () => getUserNavigators(userId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: fields, isLoading: fieldsLoading } = useQuery(
    'getUserFields',
    () => getUserMaps(userId),
    { refetchOnWindowFocus: false },
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('isRequired')),
    date: Yup.date()
    .required(t('isRequired'))
    .typeError(t('validDate'))
    .test({
      name: 'afterDate',
      exclusive: false,
      message: t('dateNoPast'),
      test: function(value) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const val = value;
        val.setHours(0, 0, 0, 0);
        return today.getTime() <= value.getTime();
      },
    }),
    fieldId: Yup.object().required(t('isRequired')),
    machineId: Yup.object().required(t('isRequired')),
    navigatorId: Yup.object().required(t('isRequired')),
  });

  const handleSubmit = async order => {
    const payload = {
      ...order,
      machineId: order.machineId.id,
      navigatorId: order.navigatorId.id,
      fieldId: order.fieldId.id,
      agrotechnicalMethod: order.machineId.work,
    };
    mutate(payload, {
      onSuccess: () => {
        const message = selectedOrder ? t('orderCreated') : t('orderChanged');
        toast.success(message);
        queryClient.refetchQueries(['getUserOrders']);
        closeModal();
      },
      onError: (error) => {
        if (error.response.data.message.includes('Choose another name')) {
          toast.error(t('orderExists'));
        }
      }
    });
  };

  if (fieldsLoading || navigatorsLoading || machinesLoading) {
    return <CircularLoader />;
  }

  const initialValues = selectedOrder
    ? {
        ...selectedOrder,
        machineId: machines.find(
          machine => machine.id === selectedOrder.machineId,
        ),
        navigatorId: navigators.find(
          navigator => navigator.id === selectedOrder.navigatorId,
        ),
        fieldId: fields.find(field => field.id === selectedOrder.fieldId),
      }
    : newOrderProps;

  function workTypesToArray(workTypes) {
    const result = [];
    for (const key in workTypes) {
      if (Array.isArray(workTypes[key])) {
        result.push(...workTypes[key]);
      } else {
        result.push(workTypes[key]);
      }
    }
    return result;
  }

  const formattedWorkTypes = workTypesToArray(workTypes);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {formik => (
        <Dialog open={modalOpen} onClose={closeModal}>
          <DialogTitle>
            {selectedOrder ? t('orderChange') : t('orderCreate')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('name')}
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="sr"
                >
                  <DateField
                    label={t('date')}
                    variant="outlined"
                    fullWidth
                    name="date"
                    format="DD/MM/YYYY"
                    disabled={selectedOrder}
                    value={formik.values.date}
                    onChange={val => formik.setFieldValue('date', val)}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label={t('field')}
                  options={fields}
                  getOptionLabel={option => option.name || ''}
                  variant="outlined"
                  fullWidth
                  name="fieldId"
                  value={formik.values.fieldId}
                  onChange={(e, val) => formik.setFieldValue('fieldId', val)}
                  disableClearable
                  noOptionsText={t('noResults')}
                  renderInput={params => (
                    <TextField
                      label={t('field')}
                      variant="outlined"
                      fullWidth
                      name="fieldId"
                      value={formik.values.fieldId}
                      error={
                        formik.touched.fieldId && Boolean(formik.errors.fieldId)
                      }
                      helperText={
                        formik.touched.fieldId && formik.errors.fieldId
                      }
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label={t('machine')}
                  options={
                    formik.values.agrotechnicalMethod
                      ? machines.filter(
                          machine =>
                            machine.work === formik.values.agrotechnicalMethod,
                        )
                      : machines
                  }
                  getOptionLabel={option => option.name || ''}
                  variant="outlined"
                  fullWidth
                  name="machineId"
                  value={formik.values.machineId}
                  onChange={(e, val) => {
                    formik.setFieldValue('machineId', val);
                    formik.setFieldValue('agrotechnicalMethod', val.work);
                  }}
                  disableClearable
                  noOptionsText={t('noResults')}
                  renderInput={params => (
                    <TextField
                      label={t('machine')}
                      variant="outlined"
                      fullWidth
                      name="machineId"
                      value={formik.values.machineId}
                      error={
                        formik.touched.machineId &&
                        Boolean(formik.errors.machineId)
                      }
                      helperText={
                        formik.touched.machineId && formik.errors.machineId
                      }
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label={t('navigator')}
                  options={navigators}
                  getOptionLabel={option => option.name || ''}
                  variant="outlined"
                  fullWidth
                  name="navigatorId"
                  value={formik.values.navigatorId}
                  onChange={(e, val) =>
                    formik.setFieldValue('navigatorId', val)
                  }
                  disableClearable
                  noOptionsText={t('noResults')}
                  renderInput={params => (
                    <TextField
                      label={t('navigator')}
                      variant="outlined"
                      fullWidth
                      name="navigatorId"
                      value={formik.values.navigatorId}
                      error={
                        formik.touched.navigatorId &&
                        Boolean(formik.errors.navigatorId)
                      }
                      helperText={
                        formik.touched.navigatorId && formik.errors.navigatorId
                      }
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label={t('agrotechnicalMethod')}
                  options={formattedWorkTypes}
                  variant="outlined"
                  fullWidth
                  name="agrotechnicalMethod"
                  value={formik.values.agrotechnicalMethod}
                  onChange={(e, val) => {
                    if (val) {
                      formik.setFieldValue('machineId', '');
                    }
                    formik.setFieldValue('agrotechnicalMethod', val);
                  }}
                  noOptionsText={t('noResults')}
                  renderInput={params => (
                    <TextField
                      label={t('agrotechnicalMethod')}
                      variant="outlined"
                      fullWidth
                      name="agrotechnicalMethod"
                      value={formik.values.agrotechnicalMethod}
                      error={
                        formik.touched.agrotechnicalMethod &&
                        Boolean(formik.errors.agrotechnicalMethod)
                      }
                      helperText={
                        formik.touched.agrotechnicalMethod &&
                        formik.errors.agrotechnicalMethod
                      }
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} variant="outlined" color="default">
              {t('cancel')}
            </Button>
            <Button
              onClick={formik.submitForm}
              variant="outlined"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
