import React, { useState, useEffect } from 'react';
import Sidebar from 'components/Sidebar';
import { Box, Button } from '@material-ui/core';
import {
  Autocomplete,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  CardActions,
  InputAdornment,
} from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { getUserMachines } from 'endpoints/machines';
import { getUserNavigators } from 'endpoints/navigators';
import { getUserMaps } from 'endpoints/fieldMaps';
import { downloadJob, getFinishedJobs, getSingleJob } from 'endpoints/jobs';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CircularLoader from 'components/Loader/CircularLoader/CircularLoader';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteJobModal from './DeleteJobModal';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default function Jobs() {
  const userId = JSON.parse(localStorage.getItem('userData'))?.id;

  const { t } = useTranslation('form');

  const [date, setDate] = useState(null);
  const [navigatorId, setNavigatorId] = useState('');
  const [machineName, setmachineName] = useState('');
  const [fieldName, setfieldName] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [jobForDeletion, setJobForDeletion] = useState(null);

  const handleDateChange = date => {
    setDate(date);
  };

  const classes = useStyles();

  const { data: machines, isLoading: machinesLoading } = useQuery(
    'getUserMachines',
    () => getUserMachines(userId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: navigators, isLoading: navigatorsLoading } = useQuery(
    'getUserNavigators',
    () => getUserNavigators(userId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: fields, isLoading: fieldsLoading } = useQuery(
    'getUserFields',
    () => getUserMaps(userId),
    { refetchOnWindowFocus: false },
  );

  const { data: jobs, isLoading: jobsLoading, refetch } = useQuery(
    'getFinishedJobs',
    () =>
      getFinishedJobs(userId, { navigatorId, machineName, fieldName, date }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const openDeletionModal = (event, job) => {
    event.stopPropagation();
    setDeleteModalOpen(true);
    setJobForDeletion(job);
  };

  const closeDeletionModal = () => {
    setDeleteModalOpen(false);
    setJobForDeletion(null);
  };

  const { mutate: download, isLoading } = useMutation(downloadJob, {
    onSuccess: res => {
      const bytes = new Uint8Array(res.data);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Izveštaj.pdf';
      link.click();
    },
  });

  const {
    data: singleJob,
    isLoading: singleJobLoading,
    refetch: refetchJob,
  } = useQuery('getSingleJob', () => getSingleJob(selectedJob), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (selectedJob) {
      refetchJob();
    }
  }, [refetchJob, selectedJob]);

  useEffect(() => {
    refetch();
  }, [navigatorId, machineName, fieldName, date, refetch]);

  if (machinesLoading || navigatorsLoading || fieldsLoading || jobsLoading) {
    return <CircularLoader />;
  }

  function secondsToHMS(seconds) {
    if (!seconds) {
      return null;
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(secs).padStart(2, '0');

    // Format as hh:mm:ss
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

  return (
    <>
      <DeleteJobModal
        modalOpen={deleteModalOpen}
        selectedJob={jobForDeletion}
        handleClose={closeDeletionModal}
      />
      <Sidebar>
        <Box p={2} pb={10}>
          <Box mb={1}>
            <Autocomplete
              options={navigators}
              getOptionLabel={option => option.name}
              onChange={(e, val) => setNavigatorId(val)}
              noOptionsText={t('noResults')}
              renderInput={params => (
                <TextField
                  label={t('navigator')}
                  variant="outlined"
                  fullWidth
                  name="distributors"
                  {...params}
                />
              )}
            />
          </Box>
          <Box mb={1}>
            <Autocomplete
              options={machines}
              getOptionLabel={option => option.name}
              onChange={(e, val) => setmachineName(val)}
              noOptionsText={t('noResults')}
              renderInput={params => (
                <TextField
                  label={t('machine')}
                  variant="outlined"
                  fullWidth
                  name="distributors"
                  {...params}
                />
              )}
            />
          </Box>
          <Box mb={1}>
            <Autocomplete
              options={fields}
              getOptionLabel={option => option.name}
              onChange={(e, val) => setfieldName(val)}
              noOptionsText={t('noResults')}
              renderInput={params => (
                <TextField
                  label={t('field')}
                  variant="outlined"
                  fullWidth
                  name="distributors"
                  {...params}
                />
              )}
            />
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label={t('date')}
              value={date}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  readOnly: true,
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
              localeText={{ clearButtonLabel: 'Ukloni' }}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          <Box mt={2} mb={2}>
            <Divider>
              <Typography>Poslovi</Typography>
            </Divider>
          </Box>
          <List style={{ height: 'calc(100vh - 409px)', overflow: 'scroll' }}>
            {jobs.length === 0 && <Typography>{t('noResults')}</Typography>}
            {jobs?.map(job => (
              <ListItem key={job.id} disablePadding disableGutters>
                <ListItemButton
                  selected={selectedJob === job.id}
                  onClick={() => setSelectedJob(job.id)}
                >
                  <Card style={{ width: '100%' }}>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                      <IconButton
                        onClick={e => openDeletionModal(e, job)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                    <CardContent>
                      <Typography>{job.fieldName}</Typography>
                      <Typography>{job.machineName}</Typography>
                      <Typography>
                        {dayjs(job.endDate).format('DD/MM/YYYY HH:MM:ss')}h
                      </Typography>
                    </CardContent>
                  </Card>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Sidebar>
      {singleJob ? (
        <Box
          width="100%"
          style={{ height: 'calc(100vh - 64px)', overflow: 'scroll' }}
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('info')}</Typography>
            </AccordionSummary>
            <Box display="flex" p={1}>
              <Typography style={{ fontWeight: 'bold' }}>
                {t('field')}:{' '}
              </Typography>
              <Typography>{singleJob?.fieldName}</Typography>
            </Box>
            <Box display="flex" p={1}>
              <Typography style={{ fontWeight: 'bold' }}>
                {t('dateAndTime')}:{' '}
              </Typography>
              <Typography>
                {singleJob?.endDate
                  ? dayjs(singleJob?.endDate).format('DD/MM/YYYY HH:MM:ss')
                  : null}
              </Typography>
            </Box>
            <Box display="flex" p={1}>
              <Typography style={{ fontWeight: 'bold' }}>
                {t('navigator')}:{' '}
              </Typography>
              <Typography>{singleJob?.navigatorName}</Typography>
            </Box>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('statistics')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" justifyContent="space-around">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Agrotehnička mera
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.agrotechnicalMethod}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {t('field')}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.fieldName}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Klijent
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.operator}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Datum početka
                        </Typography>
                        <Typography variant="body2" component="p">
                          {dayjs(singleJob?.startDate).format('DD/MM/YYYY')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Vreme početka
                        </Typography>
                        <Typography variant="body2" component="p">
                          {dayjs(singleJob?.startDate).format('HH:MM:ss')} h
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Datum završetka
                        </Typography>
                        <Typography variant="body2" component="p">
                          {dayjs(singleJob?.endDate).format('DD/MM/YYYY')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Vreme završetka
                        </Typography>
                        <Typography variant="body2" component="p">
                          {dayjs(singleJob?.endDate).format('HH:MM:ss')} h
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Priključna mašina
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.machineName}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Ukupno vreme rada
                        </Typography>
                        <Typography variant="body2" component="p">
                          {secondsToHMS(singleJob?.totalWorkTime) || '#N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Urađena površina
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.processedSurface}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Prosečna brzina
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.averageSpeed} km/h
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Preklapanje
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.overlapPercentage} %
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Preklopljena površina
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.overlapSurface} ha
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Prosečan radni učinak na h
                        </Typography>
                        <Typography variant="body2" component="p">
                          {singleJob?.performancePerHour
                            ? `${singleJob?.performancePerHour} ha`
                            : '#N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Button
            onClick={() => download(singleJob.id)}
            style={{ margin: 20 }}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Skini izvestaj
          </Button>
        </Box>
      ) : null}
    </>
  );
}
