import React, { useRef } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Divider,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import newMachine from './newMachineProps';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createMachine, updateMachine } from 'endpoints/machines';
import { hangingTypes, machineTypes, workTypes } from 'const/roles';
import carriedAttached from '../../../assets/images/machines/carriedAttached.png';
import carriedSprayer from '../../../assets/images/machines/carriedSprayer.png';
import carriedSpreader from '../../../assets/images/machines/carriedSpreader.png';
import carriedDrill from '../../../assets/images/machines/carriedDrill.png';
import draggedAttached from '../../../assets/images/machines/draggedAttached.png';
import draggedSprayer from '../../../assets/images/machines/draggedSprayer.png';
import draggedDrill from '../../../assets/images/machines/draggedDrill.png';
import draggedSpreader from '../../../assets/images/machines/draggedSpreader.png';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const Big = require('big.js');

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function CreateMachineModal({
  modalOpen,
  closeModal,
  selectedMachine,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();
  const uploadRef = useRef();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    selectedMachine ? updateMachine : createMachine
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('isRequired')),
    machineType: Yup.string().required(t('isRequired')),
    workingWidth: Yup.array().of(
      Yup.object().shape({
        width: Yup.number(t('mustBeNumber'))
          .typeError(t('mustBeNumber'))
          .positive(t('mustBePositive'))
          .required(t('isRequired')),
      })
    ),
    hangingType: Yup.string().required(t('isRequired')),
    sideDrift: Yup.number(t('mustBeNumber'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .required(t('isRequired')),
    distanceH: Yup.number(t('mustBeNumber'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .when('hangingType', (hangingType, schema) => {
        return hangingType[0] === 'DRAGGED'
          ? schema.required(t('isRequired'))
          : schema;
      }),
    startTime: Yup.number(t('mustBeNumber'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .when('machineType', (machineType, schema) => {
        return machineType[0] === 'SPRAYER'
          ? schema
              .required(t('isRequired'))
              .test('isNumber', t('mustBeNumber'), val => typeof val === 'number')
          : schema;
      }),
    stopTime: Yup.number(t('mustBeNumber'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .when('machineType', (machineType, schema) => {
        return machineType[0] === 'SPRAYER'
          ? schema
              .required(t('isRequired'))
              .test('isNumber', t('mustBeNumber'), val => typeof val === 'number')
          : schema;
      }),
    work: Yup.string().required(t('isRequired')),
  });

  const sumWidth = arr => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].width;
    }
    return sum;
  };

  function metersToCentimeters(meters) {
    const metersBig = new Big(meters);
    const centimeters = metersBig.times(100);
    return centimeters.toString();
  }

  const handleSubmit = async machine => {
    const payload = { ...machine };
    if (payload.machineType !== 'SPRAYER') {
      delete payload.stopTime;
      delete payload.startTime;
    }
    if (payload.hangingType !== 'DRAGGED') {
      delete payload.distanceL;
    }
    payload.workingWidth.forEach((ele, index) => (ele.ordinal = index));
    payload.workingWidth.forEach(ele => ele.width = ele.width / 100);
    payload.sideDrift = payload.sideDrift / 100;
    payload.width = sumWidth(payload.workingWidth);
    payload.section = payload.workingWidth.length;

    mutate(payload, {
      onSuccess: () => {
        const message = selectedMachine
          ? t('updatedMachine')
          : t('createdMachine');
        toast.success(message);
        queryClient.refetchQueries(['getUserMachines']);
        closeModal();
      },
    });
  };

  const resolvePicture = (hangingType, machineType) => {
    if (hangingType === 'DRAGGED') {
      if (machineType === 'SEED_DRILL') {
        return draggedDrill;
      } else if (machineType === 'SPREADER') {
        return draggedSpreader;
      } else if (machineType === 'SPRAYER') {
        return draggedSprayer;
      } else if (machineType === 'ATTACHMENT') {
        return draggedAttached;
      }
    } else if (hangingType === 'CARRIED') {
      if (machineType === 'SEED_DRILL') {
        return carriedDrill;
      } else if (machineType === 'SPREADER') {
        return carriedSpreader;
      } else if (machineType === 'SPRAYER') {
        return carriedSprayer;
      } else if (machineType === 'ATTACHMENT') {
        return carriedAttached;
      }
    }
  };

  const initialValues = selectedMachine
    ? {
        ...selectedMachine,
        startTime:
          selectedMachine.machineType === 'SPRAYER'
            ? selectedMachine.startTime
            : '',
        stopTime:
          selectedMachine.machineType === 'SPRAYER'
            ? selectedMachine.stopTime
            : '',
        distanceL:
          selectedMachine.hangingType === 'DRAGGED'
            ? selectedMachine.distanceL
            : '',
        sideDrift: metersToCentimeters(selectedMachine.sideDrift),
        workingWidth: selectedMachine.workingWidth.map((ele) => {return {...ele, width: metersToCentimeters(ele.width)};}),
      }
    : newMachine;

  const uploadSingleMachine = async payload => {
    try {
      const res = await createMachine(payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const uploadMachine = e => {
    if (!e?.target?.files) {
      return;
    }
    if (e.target.files.length === 1) {
    const file = e.target.files[0];
    if (file && (file.type === 'application/json' || file.name.includes('.machines'))) {
      const reader = new FileReader();
      reader.onload = async event => {
        try {
          const jsonObject = JSON.parse(event.target.result);
          if (!Array.isArray(jsonObject)) {
            delete jsonObject.id;
            mutate(jsonObject, {
              onSuccess: () => {
                const message = selectedMachine
                  ? t('updatedMachine')
                  : t('createdMachine');
                toast.success(message);
                queryClient.refetchQueries(['getUserMachines']);
                closeModal();
              },
              onError: error => {
                toast.error(error?.response?.data?.message);
              },
            });
          } else {
            const apiPromises = [];
            for (const payload of jsonObject) {
              delete payload.id;
              const promise = uploadSingleMachine(payload);
              apiPromises.push(promise);
            }
            try {
              await Promise.all(apiPromises);
              toast.success(t('createdMachines'));
              queryClient.refetchQueries(['getUserMachines']);
              closeModal();
            } catch (error) {
              toast.error(t('errorHasOccurred'));
            }
          }
        } catch (error) {
          toast.error(t('errorHasOccurred'));
        }
      };
      reader.readAsText(file);
    } else {
      toast.error(t('jsonRequired'));
    }
  } else {
    for (let i = 0; i <= e.target.files.length - 1; i++) {
      const file = e.target.files[i];
    if (file && (file.type === 'application/json' || file.name.includes('.machines'))) {
      const reader = new FileReader();
      reader.onload = async event => {
        try {
          const jsonObject = JSON.parse(event.target.result);
          if (!Array.isArray(jsonObject)) {
            delete jsonObject.id;
            mutate(jsonObject, {
              onSuccess: () => {
                const message = selectedMachine
                  ? t('updatedMachine')
                  : t('createdMachine');
                toast.success(message);
                queryClient.refetchQueries(['getUserMachines']);
                closeModal();
              },
              onError: error => {
                toast.error(error?.response?.data?.message);
              },
            });
          } else {
            const apiPromises = [];
            for (const payload of jsonObject) {
              delete payload.id;
              const promise = uploadSingleMachine(payload);
              apiPromises.push(promise);
            }
            try {
              await Promise.all(apiPromises);
              toast.success(t('createdMachines'));
              queryClient.refetchQueries(['getUserMachines']);
              closeModal();
            } catch (error) {
              toast.error(t('errorHasOccurred'));
            }
          }
        } catch (error) {
          toast.error(t('errorHasOccurred'));
        }
      };
      reader.readAsText(file);
    } else {
      toast.error(t('jsonRequired'));
    }
    }
  }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {formik => (
          <Dialog
            open={modalOpen}
            onClose={closeModal}
            fullscreen="true"
            maxWidth="xl"
          >
            <DialogTitle>
              {selectedMachine ? t('machineChange') : t('machineCreate')}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid container item xs={12} md={6} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('name')}
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('machineType')}
                      variant="outlined"
                      fullWidth
                      select
                      name="machineType"
                      value={formik.values.machineType}
                      onChange={e => {
                        formik.handleChange(e);

                        formik.setFieldValue(
                          'work',
                          e.target.value !== 'ATTACHMENT'
                            ? workTypes[e.target.value]
                            : ''
                        );
                      }}
                      error={
                        formik.touched.machineType &&
                        Boolean(formik.errors.machineType)
                      }
                      helperText={
                        formik.touched.machineType && formik.errors.machineType
                      }
                    >
                      {machineTypes.map(item => (
                        <MenuItem key={item.type} value={item.type}>
                          {t(`machineTypes.${item.type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12} spacing={2} container>
                    {formik.values.workingWidth.map((item, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <TextField
                          label={`Sekcija ${index + 1}(cm)`}
                          name={`workingWidth[${index}].width`}
                          type="number"
                          variant="outlined"
                          value={item.width}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.workingWidth &&
                            formik.touched.workingWidth[index] &&
                            formik.touched.workingWidth[index].width &&
                            Boolean(formik.errors.workingWidth?.[index]?.width)
                          }
                          helperText={
                            formik.touched.workingWidth &&
                            formik.touched.workingWidth[index] &&
                            formik.touched.workingWidth[index].width &&
                            formik.errors.workingWidth?.[index]?.width
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={
                                    formik.values.workingWidth.length === 1
                                  }
                                  onClick={() => {
                                    const newWorkingWidth = formik.values.workingWidth.filter(
                                      (_, i) => i !== index
                                    );
                                    formik.setFieldValue(
                                      'workingWidth',
                                      newWorkingWidth
                                    );
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ))}
                    <span style={{ marginTop: 10 }}>
                      <IconButton
                        onClick={() => {
                          const newWorkingWidth = [
                            ...formik.values.workingWidth,
                            {
                              ordinal: formik.values.workingWidth.length + 1,
                              width: '',
                            },
                          ];
                          formik.setFieldValue('workingWidth', newWorkingWidth);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('hangingType')}
                      variant="outlined"
                      fullWidth
                      select
                      name="hangingType"
                      value={formik.values.hangingType}
                      onChange={e => {
                        formik.setValues({
                          ...newMachine,
                          name: formik.values.name,
                          hangingType: e.target.value,
                          machineType: formik.values.machineType,
                          section: formik.values.section,
                          width: formik.values.width,
                          workingWidth: formik.values.workingWidth,
                          id: formik.values.id,
                          work: formik.values.work,
                        });
                      }}
                      error={
                        formik.touched.hangingType &&
                        Boolean(formik.errors.hangingType)
                      }
                      helperText={
                        formik.touched.hangingType && formik.errors.hangingType
                      }
                    >
                      {hangingTypes.map(item => (
                        <MenuItem key={item.type} value={item.type}>
                          {t(`hangingTypes.${item.type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('sideDrift')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="sideDrift"
                      value={formik.values.sideDrift}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.sideDrift &&
                        Boolean(formik.errors.sideDrift)
                      }
                      helperText={
                        formik.touched.sideDrift && formik.errors.sideDrift
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('distanceH')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="distanceH"
                      value={formik.values.distanceH}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.distanceH &&
                        Boolean(formik.errors.distanceH)
                      }
                      helperText={
                        formik.touched.distanceH && formik.errors.distanceH
                      }
                    />
                  </Grid>
                  {formik.values.hangingType === 'DRAGGED' ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={t('distanceL')}
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="distanceL"
                        value={formik.values.distanceL}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.distanceL &&
                          Boolean(formik.errors.distanceL)
                        }
                        helperText={
                          formik.touched.distanceL && formik.errors.distanceL
                        }
                      />
                    </Grid>
                  ) : null}
                  {formik.values.machineType === 'SPRAYER' ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t('startTime')}
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="startTime"
                          value={formik.values.startTime}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.startTime &&
                            Boolean(formik.errors.startTime)
                          }
                          helperText={
                            formik.touched.startTime && formik.errors.startTime
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t('stopTime')}
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="stopTime"
                          value={formik.values.stopTime}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.stopTime &&
                            Boolean(formik.errors.stopTime)
                          }
                          helperText={
                            formik.touched.stopTime && formik.errors.stopTime
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} md={6}>
                    {formik.values.machineType ===
                      machineTypes.find(
                        machine => machine.type === 'ATTACHMENT'
                      ).type && (
                      <Autocomplete
                        label={t('work')}
                        options={workTypes.AttachedMachines}
                        getOptionLabel={option =>
                          t(option ? `workTypes.${option}` : '')
                        }
                        variant="outlined"
                        fullWidth
                        name="work"
                        value={formik.values.work}
                        onChange={(e, val) => formik.setFieldValue('work', val)}
                        disableClearable
                        noOptionsText={t('noResults')}
                        renderInput={params => (
                          <TextField
                            label={t('work')}
                            variant="outlined"
                            fullWidth
                            name="work"
                            value={formik.values.work}
                            error={
                              formik.touched.work && Boolean(formik.errors.work)
                            }
                            helperText={
                              formik.touched.work && formik.errors.work
                            }
                            {...params}
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6} justify="center">
                  <Box onClick={resolvePicture} textAlign="center">
                    <img
                      src={resolvePicture(
                        formik.values.hangingType,
                        formik.values.machineType
                      )}
                      width="auto"
                      height="500px"
                      alt=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                component="label"
                variant="outlined"
                color="secondary"
                startIcon={<CloudUploadIcon />}
                style={{ marginRight: 'auto' }}
              >
                {t('uploadMachine')}
                <VisuallyHiddenInput
                  accept=".json, .machines"
                  ref={uploadRef}
                  onClick={() => (uploadRef.current.value = null)}
                  onInput={uploadMachine}
                  type="file"
                  multiple
                />
              </Button>
              <Button onClick={closeModal} variant="outlined" color="default">
                {t('cancel')}
              </Button>
              <Button
                onClick={formik.submitForm}
                variant="outlined"
                color="primary"
              >
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
}
